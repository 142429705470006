<div class="container form-border punk">
    <form novalidate #f="ngForm" (ngSubmit)="onSubmit()">
        <h2>ReFocus Data Strength Calculator</h2>
        <div class="mb-3">
            <label for="fullName" class="form-label calculator-header">Full Name</label>
            <input required type="text" class="form-control" id="fullName" name="fullName" [(ngModel)]="model.fullName" #fullName="ngModel">
            <div class="form-control-feedback"
			     *ngIf="fullName.errors && (fullName.dirty || fullName.touched)">
				<p style="color:red" *ngIf="fullName.errors.required">Full name is required</p>
			</div>
        </div>
        <div class="mb-3">
            <label for="company" class="form-label calculator-header">Company</label>
            <input type="text" class="form-control" id="company" name="company" [(ngModel)]="model.company">
        </div>
        <div class="mb-3 form-group">
            <label for="email" class="form-label calculator-header">Email</label>
            <input required type="email" class="form-control" id="email" placeholder="lauren@refocusai.com" name="email" [(ngModel)]="model.email" required  pattern="[^ @]*@[^ @]*" #email="ngModel">
            <div class="form-control-feedback"
		     *ngIf="email.errors && (email.dirty || email.touched)">
			<p style="color:red" *ngIf="email.errors.required">Email is required</p>
			<p *ngIf="email.errors.pattern">Email must contain at least the @ character</p>
		</div>
        </div>
        <div class="mb-3 form-group">
            <label for="questions" class="form-label calculator-header">What else would you like to know about your data?</label>
            <textarea class="form-control" id="questions" rows="3" name="questions" [(ngModel)]="model.questions"></textarea>
        </div>
        <div>
            <button (click)="onSubmit(); toggleSuccess()" type="button" class="btn container" [disabled]="f.invalid">Submit</button>
        </div>
        <div>
            <span  (click)="toggle()" class="go-back"> Go Back</span>
        </div>
    </form>
</div>