<app-header></app-header>
        <div class="row" [@fadeInAnimation]>
            <div class="col-lg-6 order-lg-12">
                <app-calculator-form  *ngIf="showCalculatorForm" (next)="toggle()" (calculatorFormDataOutput)="setCalculatorData($event)"></app-calculator-form>
                <app-contact-form *ngIf="showContactForm" (submit)="toggleSuccess()" (goBack)="toggle()"  (contactFormDataOutput)="setContactData($event)"> </app-contact-form >
                <app-success-form *ngIf="showSuccessForm dataScore" [dataScore]="dataScore" (back)="toggleSuccess()"></app-success-form>
            </div>
            <div  class="col-lg-6 order-lg-1 fade-in" id="new">
                <div id="page-text" class="row" >
                <div  id="one" class="fade-in one container-header">
                    <br>
                    <h1>Unlock Your Data</h1> 
                        <h3>Use the Data Calculator to find out the quality of your data to 
                            sell more, churn less, and provide an exceptional customer experience every time</h3>
                </div>
                <div id="two" class="fade-in two">
                    <h2><a href="https://blog.refocusai.com/data-calculator/" class="headers-left" style="display: inline-block">Supercharge your sales</a></h2> 
                        <h2> </h2>
                        <p>Empowering your sales teams by collecting the right data starts by understanding the data you have today. Not all data is created or gathered equally. How structured and complete your data matters. 
                        <br>
                        <br>
                        With the right data, your sales department will both generate and use its own data, increasing the efficiency and close ratios. Use the Data Calculator to understand how usable your data is today in predicting when and what your customers and prospects will buy.
                        </p>
                </div>
                <div id="three" class="fade-in three">
                    <h2><a href="https://blog.refocusai.com/data-calculator/" class="headers-left" style="display: inline-block">Retain more customers</a></h2> 
                        <p>Every customer has different needs, and how you can best retain them is already locked within your data. Tailor each customer retention strategy individually, using your data to identify trends and make business decisions. Use the Data Calculator to understand how quickly your business can start using its data to boost retention.
                        </p>
                </div>
                <div id="four" class="fade-in four">      
                    <h2><a href="https://blog.refocusai.com/data-calculator/" class="headers-left" style="display: inline-block">Enhance the Customer Experience</a></h2> 
                        <p>Individualize your company’s sales strategy to approach a prospect at exactly the right time, providing an amazing customer experience. When a purchase is top-of-mind for your customer, closing the sale is always simpler. Identify the best time to approach your sale using the data your company already has in its CRM, policy-management system, or other repository. Use the Data Calculator to see how many opportunities your company has to close new business and provide a better experience.
                        </p>
                </div>
            </div>
        </div>
        </div>