<div class="form-border punk">
    <form novalidate #f="ngForm" (ngSubmit)="onSubmit()">
        <h2 class="form-header">ReFocus Data Strength Calculator</h2>      
                <label for="calculator-form" class="calculator-header">What type of data do you gather? (Check all that apply)</label>
                <div class="container form-check-container pl-1">
                    <div class="form-check">  
                            <input class="form-check-input form-control" type="checkbox" value="" name="checkboxBinary" id="checkboxBinary" [(ngModel)]="model.checkboxBinary">
                            <label class="form-label" for="checkboxBinary">Data that is yes/no, 1/0, or other binary values</label>
                        </div>
                        <div class="form-check">   
                            <input class="form-check-input form-control" type="checkbox" value="" name="checkboxNumerical" id="checkboxNumerical" [(ngModel)]="model.checkboxNumerical">
                            <label class="form-label" for="checkboxNumerical">Data on pricing, sizing, or other numerical values </label>
                        </div>
                        <div class="form-check">  
                            <input class="form-check-input form-control" type="checkbox" value="" name="checkboxDates" id="checkboxDates" [(ngModel)]="model.checkboxDates">
                            <label class="form-label" for="checkboxDates">Data for birth dates, start & end dates, or other calendar date values</label>
                        </div>     
                    </div>  
                    

                        <div class="row">
                            <div class="form-group col-md-6">
                                <div class="row">
                                    <div class="col-12">
                                        <div>
                                            <label for="dataPoints" class="form-label calculator-header">Number of Data Points </label> 
                                            <div class="help-tip"><p>Data Points are the amount of colums you have. Examples include age, sex etc. </p></div> 
                                            <input [ngStyle]="dataPoints.errors && (dataPoints.dirty || dataPoints.touched)?{'border-color':'red'} : {'border-color': '#D1D1D1'}" required type="number" name="dataPoints"  class="form-control " id="dataPoints" name="dataPoints" [(ngModel)]="model.dataPoints" #dataPoints="ngModel">
                                            <div class="form-control-feedback"
                                            *ngIf="dataPoints.errors && (dataPoints.dirty || dataPoints.touched)">
                                           <p style="color:red" *ngIf="dataPoints.errors.required">Enter the number of data points your data set has to continue</p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    
                            <div class="form-group  col-md-6">
                                <label for="attributes" class="form-label calculator-header">Number of Attributes</label>
                                <div class="help-tip"><p>Attributes are the rows that you have. For example, number of customers or policies that the customers have? </p></div>
                                <input [ngStyle]="attributes.errors && (attributes.dirty || attributes.touched)?{'border-color':'red'} : {'border-color': '#D1D1D1'}"  required type="number" class="form-control" id="attributes" name="attributes" [(ngModel)]="model.attributes" #attributes="ngModel">
                                <div class="form-control-feedback"
                                *ngIf="attributes.errors && (attributes.dirty || attributes.touched)">
                                <p style="color:red" *ngIf="attributes.errors.required">Enter the number of attributes your data set has to continue</p>
                                </div>
                            </div>
                        </div>
                   

                    <div class="slidecontainer">
                    <label for="myRange" class="form-label">How complete is your data?</label>
                    <input type="range" name="myRange" min="1" max="100" value="0" id="myRange" class="form-range slider" name="myRange" oninput="this.nextElementSibling.value = this.value" [(ngModel)]="model.myRange" #myRange="ngModel">
                    <output>50</output> <span>%</span>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label  for="updateFreq" class="calculator-header form-label">Data Update Frequency</label>  
                                    <select required id="updateFreq" name="updateFreq" class="form-select form-control" aria-label="Default select example" [(ngModel)]="updateFreq" (change)="dropdownChangeOne($event)">
                                        <option disabled selected value="undefined">Choose frequency</option>
                                        <option *ngFor="let o of freqArray" [value]="o" >{{ o }}</option>
                                      </select>                  
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <input *ngIf="showFrequencyInput" type="text" name="frequency" class="hiddenInput form-control" id="frequency"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="dataLocation" class="calculator-header form-label">Location of Data</label>
                                    <select required id="dataLocation" name="dataLocation"  class="form-select form-control" aria-label="Default select example" [(ngModel)]="dataLocation" (change)="dropdownChangeTwo($event)" >
                                        <option disabled selected value="undefined">Choose Location</option>
                                        <option *ngFor="let o of locationArray" [value]="o" >{{ o }}</option>
                                      </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <input *ngIf="showLocationInput" type="text" name="location" class="hiddenInput form-control" id="location"/>
                            </div>
                        </div>
                    </div>
                    <button [disabled]="f.invalid" type="button" (click)="onSubmit(); toggle()" class="btn btn-next container" id="check">Next</button>
                </div>
    </form>
</div>
