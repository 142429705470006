<header class="navbar  navbar-static-top" data-transparent-header="true">

        <div class="navbar-header">
            <div id="logo" class="ml-3">
                <a href="https://refocusai.com/"> <img class="logo-trans logo-reg lazyloaded" src="https://refocusai.com/wp-content/uploads/2020/06/logo-368x100.png" height="35" width="129" alt="ReFocus AI">
                </a>
            </div>
        </div>
        <nav class="navbar navbar-expand-lg navbar-expand-sm" role="navigation">
            <div>
                <ul id="calculator-nav-menu" class="nav navbar-nav">
                    <li class="menu-item menu-item-type-post_type">
                        <a href="https://refocusai.com/" style="display: inline-block"> Home</a>
                    </li>
                    <li class="menu-item menu-item-type-post_type">
                        <a href="https://refocusai.com/contact/" style="display: inline-block">Contact</a>
                    </li>
                </ul>
            </div>
        </nav>


</header>